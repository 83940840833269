document.addEventListener("turbolinks:load", function() {
	$(document).on("click","#carton_labels", function() {
		cartonLabel = $(this).prop('checked');
		if(cartonLabel) {
      $("#carton_label_format_1").removeAttr("disabled");
			$("#carton_label_format_2").removeAttr("disabled");
		}
    else {
			$("#carton_label_format_1").prop('disabled', true);
			$("#carton_label_format_2").prop('disabled', true);
		}
	});
})

document.addEventListener("turbolinks:load", function() {
	$(document).on("change","select[data-license-update]", function() {
    newLicense = $(this).val();
    if(newLicense == "1") {
      $("#plant_photo_license_end").removeAttr("disabled");
      $("#plant_photo_license_volume").val('');
      $("#plant_photo_license_volume").prop('disabled', true);
		}
    else if(newLicense == "2") {
      $("#plant_photo_license_end").val('');
      $("#plant_photo_license_end").prop('disabled', true);
      $("#plant_photo_license_volume").removeAttr("disabled");
		}
    else if(newLicense == "3") {
      $("#plant_photo_license_end").removeAttr("disabled");
      $("#plant_photo_license_volume").removeAttr("disabled");
		}
    else {
      $("#plant_photo_license_end").val('');
      $("#plant_photo_license_end").prop('disabled', true);
      $("#plant_photo_license_volume").val('');
      $("#plant_photo_license_volume").prop('disabled', true);
		}
	});
})
